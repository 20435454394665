import React from 'react';

class Projects extends React.Component {
    
    render() {
        return (
            <div>
                <h2>Projects Page</h2>
                <main>
                    <p>This section contains information about...</p>
                </main>
            </div>
        )
    }
}



export default Projects;